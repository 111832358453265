import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Api from "src/data/api";
import { isSubscriptionStatusPastDueOrUnpaid } from "src/utils/helpers";
import { useShopifyAppBridge } from "../shopify/appBridge";

const withSubscription = (condition) => (Component) => {
    const WithSubscription = (props) => {
        const { getAppBridge } = useShopifyAppBridge();
        const isShopify = getAppBridge() !== null;
        const router = useRouter();
        const [user, setUser] = useState({});

        useEffect(() => {
            if (isShopify) {
                Api.request({ url: "/auth/shopify" }).then((res) => {
                    const status = res.data.status;
                    if (status === "new_user" || status === "no_subscription" || status === "pending_subscription") {
                        router.replace("/shopify");
                    }
                });
                return;
            }

            Api.request({ url: "/user/me" }).then(({ data }) => {
                setUser(data);

                if (!data.isStripeRequired || data.isShopifyUser || data.isShopifyRetailer) {
                    return;
                }

                if (isSubscriptionStatusPastDueOrUnpaid(data)) {
                    router.push("/manage-billing");
                    return;
                }

                if (!condition(data)) {
                    router.push("/choose-subscription");
                }
            });
        }, []);

        return (
            <>
                {(isShopify || user.isShopifyUser || user.isShopifyRetailer) ? (
                    <Component />
                ) : condition(user) ? (
                    <Component {...props} />
                ) : user.isStripeRequired ? null : (
                    <Component {...props} />
                )}
            </>
        );
    };

    return WithSubscription;
};

export default withSubscription;
