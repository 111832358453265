import React, { useState, useEffect } from "react";
import Head from "next/head";

import Api from "src/data/api";
import withAuth from "src/components/withAuth";
import Calendar from "src/components/calendar";
import Loader from "src/components/loader/fullscreen";
import withSubscription from "src/components/WithAuthorization/withSubscription";
import { isSubscriptionStatusValid } from "src/utils/helpers";

function Home() {
    const [retailerLocations, setRetailerLocations] = useState(null);

    useEffect(() => {
        Api.request({ url: "/retailer/settings/locations" })
            .then(({ data }) => setRetailerLocations(data.retailerLocations))
            .catch((err) => console.log(err));
    }, []);

    return (
        <>
            <Head>
                <title>Appointments - Tote Dashboard</title>
            </Head>

            {retailerLocations ? (
                <Calendar retailerLocations={retailerLocations} />
            ) : (
                <Loader />
            )}
        </>
    );
}

export default withAuth(withSubscription(isSubscriptionStatusValid)(Home));
